<style src="./ConnectionLevel.scss" lang="scss"></style>

<template lang="html">
  <section class="connection">
    <div class="bars">
      <div class="bar one" :class="{'off': connectionLevel < 1, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar two" :class="{'off': connectionLevel < 2, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar three" :class="{'off': connectionLevel < 3, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar four" :class="{'off': connectionLevel < 4, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar five" :class="{'off': connectionLevel < 5, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
    </div>
    <div class="conn-text"
    :class="{
      'off': connectionLevel < 1,
      'yellow': connectionColor === 'yellow',
      'red': connectionColor === 'red'}"
    >{{ connectionLabel }}</div>
  </section>
</template>

<script>
import moment from 'moment'
import MovementLogs from '@/helpers/movement_logs';
import Firebase from '@/helpers/firebase/index';

export default {
  name: "ConnectionLevel",
  components: {
  },

  props: {
    objConnection: {
      type: Object,
      default: {}
    },
    room: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      connectionColor: 'red',
      connectionLevel: 1,
      connectionLabel: 'Conexão Instável',
      wasLoggedBelowThreshold: false,
      previousLevel: 1,

      intervalId: null,
      rttArray: [],
      levelArray: []
    };
  },

  computed: {},

  watch: {
    objConnection (val) {
      this._handleObjConnection(val)
    }
  },

   beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  },

  async mounted () {
    await Firebase.setFirestoreConfig();

    this.intervalId = setInterval(() => {
      this._saveLevelRttArrayToDatabase();
    }, 1 * 60 * 1000); // 1 minuto em milissegundos
  },

  methods: {
    /**
     * Processa as informações de qualidade de conexão recebidas e atualiza o estado atual.
     * - Calcula o nível de qualidade da conexão com base nos dados de uplink e downlink.
     * - Atualiza o status da conexão e armazena o nível anterior para comparações futuras.
     * @private
     * @param {Object} obj - Objeto contendo informações sobre a qualidade da conexão.
     * @returns {void} Atualiza as propriedades internas da instância com base nos dados da conexão.
     */
    _handleObjConnection (obj) {
      if (!obj.downlinkNetworkQuality || !obj.uplinkNetworkQuality) {
        this.connectionLevel = 0
      } else {
        this.connectionLevel = this._calculateConnectionQuality({
          downlinkNetworkQuality: obj.downlinkNetworkQuality,
          uplinkNetworkQuality: obj.uplinkNetworkQuality
        })
      }
      obj.level = this.connectionLevel

      this._updateConnectionStatus(this.connectionLevel)

      this._handleSaveLog(this.connectionLevel, obj)
      this.previousLevel = this.connectionLevel || 0

      if (this.room) {
        this._updateConnectionLevalAndRtt(this.room, obj)
      }
    },

    /**
     * Atualiza o nível de conexão e RTT, e armazena o RTT localmente.
     * @param {string} room - Nome da sala.
     * @param {Object} objConnection - Objeto de conexão contendo informações de nível e RTT.
     */
    _updateConnectionLevalAndRtt(room, objConnection) {
      const level = objConnection.level;
      const rtt = objConnection.sendRttMs || 0;

      // Atualiza o Firebase com nível e RTT
      Firebase.updateRoomSpecificKey(
        room,
        'pacienteTransmissionLevel',
        `${level} (Agora RTT: ${rtt}ms)`
      );

      // Adiciona o RTT ao array local
      this.levelArray.push(level);
      this.rttArray.push(rtt);
    },

    /**
     * Salva o array de RTT no banco de dados e limpa o array local.
     */
    async _saveLevelRttArrayToDatabase() {
      if (this.rttArray.length === 0) return; // Evita salvar se o array estiver vazio
      try {
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        const payload = {
          pacId: this.objConnection.pac_id,
          room: this.room,
          levelArray: this.levelArray,
          rttArray: this.rttArray,
          timeAction: timeAction
        }
        console.warn('pay', payload)
        await MovementLogs.saveLevelRttArray(payload)
        console.log('Level/RTT salvo no banco:', this.rttArray);
        // Limpa o array após salvar
        this.levelArray = [];
        this.rttArray = [];
      } catch (error) {
        console.error('Erro ao salvar Level/RTT no banco:', error);
      }
    },

    /**
     * Lida com o salvamento de logs baseado no nível de conexão.
     * Salva um log quando:
     * - O nível cai abaixo de 3 pela primeira vez.
     * - O nível sobe para 3 ou acima após estar abaixo de 3.
     * @private
     * @param {number} currentLevel - O novo nível de conexão.
     */
    _handleSaveLog(currentLevel, payload) {
      let code = '100'
      switch (currentLevel) {
        case 0: code = '100'; break;
        case 1: code = '101'; break;
        case 2: code = '102'; break;
        case 3: code = '103'; break;
        case 4: code = '104'; break;
        case 5: code = '105'; break;
      }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
      const payloadLog = {
        pac_id: this.objConnection.pac_id,
        payload: { ...payload }
      }

      // Verifica se o nível caiu abaixo de 3 e ainda não foi logado
      if (currentLevel < 3 && !this.wasLoggedBelowThreshold) {
        this.wasLoggedBelowThreshold = true; // Marca que já foi logado
        MovementLogs.saveLog(code, timeAction, payloadLog);
        return
      }

      // Verifica se o nível subiu para 3 ou acima após estar abaixo de 3
      if (currentLevel >= 3 && this.previousLevel < 3) {
        MovementLogs.saveLog(code, timeAction, payloadLog);
        this.wasLoggedBelowThreshold = false; // Reseta a flag para futuros logs
      }
    },

    /**
     * Atualiza o status da conexão com base no nível de conexão fornecido.
     * @private
     * @param {number} level - Nível da conexão (1 a 5), onde 1 e 2 são instáveis,
     * 3 é estável com aviso e 4 e 5 são estáveis.
     */
    _updateConnectionStatus(level) {
      switch (level) {
        case 1:
        case 2:
          this.connectionColor = 'red';
          this.connectionLabel = 'Conexão Instável';
          break;
        case 3:
          this.connectionColor = 'yellow';
          this.connectionLabel = 'Conexão Estável';
          break;
        case 4:
        case 5:
          this.connectionColor = '';
          this.connectionLabel = 'Conexão Estável';
          break;
        default:
          this.connectionColor = 'red';
          this.connectionLabel = 'Sem Conexão';
          break;
      }
    },

    /**
    * Calcula o nível de qualidade da conexão com base em vários parâmetros.
    * @private
    * @param {Object} params - Os parâmetros da conexão.
    * @returns {number} - O nível de qualidade da conexão (de 1 a 5).
    */
    _calculateConnectionQuality ({ downlinkNetworkQuality, uplinkNetworkQuality }) {
      const qualityAverage = Math.ceil(
          (downlinkNetworkQuality + uplinkNetworkQuality) / 2
      )
      switch (qualityAverage) {
        case 1:
          return 5
        case 2:
          return 4
        case 3:
          return 3
        case 4:
          return 2
        case 5:
          return 1
        default:
          return 0
      }
    }
  },
};
</script>
