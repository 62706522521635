import { AgoraManager } from './AgoraManager';

export const EVENTS = {
  USER_JOINED: 'user-joined',
  USER_LEFT: 'user-left',
  USER_PUBLISHED: 'user-published',
  USER_UNPUBLISHED: 'user-unpublished',
  INFO_UPDATE: 'user-info-updated',
  NETWORK_QUALITY: 'network-quality'
};

const REMOTE_PLAYER_ID = 'remote-player-container';
const LOCAL_PLAYER_ID = 'local-player-container';

class VideoCallService {
  _remotePlayerContainer = null;
  _videoOn = true;
  _audioOn = true;
  _channelParameters = {
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null
  };
  _events = {};

  constructor() {
    this._agoraManager = new AgoraManager(this._eventHandler.bind(this));
  }

  async _eventHandler(eventName, input) {
    if (eventName === EVENTS.USER_PUBLISHED) {
      this._onRemoteUserPublished(input);
    }
    this._emit(eventName, input);
  }

  _onRemoteUserPublished({ user, mediaType }) {
    if (mediaType === 'video') {
      this._channelParameters.remoteVideoTrack = user.videoTrack;
      this._channelParameters.remoteAudioTrack = user.audioTrack;
      this._channelParameters.remoteUid = user.uid.toString();
      this._createRemotePlayerContainer();
      this._appendRemoteVideoContainer();
      this._channelParameters.remoteVideoTrack.play(
        this._remotePlayerContainer
      );
      return;
    }
    this._channelParameters.remoteAudioTrack = user.audioTrack;
    this._channelParameters.remoteAudioTrack.play();
  }

  _createRemotePlayerContainer() {
    this._removeView(REMOTE_PLAYER_ID);
    const div = document.createElement('div');
    div.setAttribute(
      'style',
      `display: flex;
      min-height: 120px;
      min-width: 50vw;
      max-width: 50vw;
      flex-direction: column;`
    );
    div.setAttribute('id', REMOTE_PLAYER_ID);
    div.setAttribute('expand', false);
    this._remotePlayerContainer = div;
  }

  _appendRemoteVideoContainer() {
    document
      .getElementById('expand_stream')
      .appendChild(this._remotePlayerContainer);
  }

  async joinChannel({ channel, token, uid, cpf }) {
    const joinParameters = {
      channelName: channel,
      token: token,
      pacCpf: cpf
    };
    await this.leaveRoom();
    const localPlayerContainer = this._createLocalPlayerContainer();
    const joinId = await this._agoraManager.join(
      this._getLocalStream(),
      localPlayerContainer,
      this._channelParameters,
      joinParameters
    );
    return joinId;
  }

  _getLocalStream() {
    return document.getElementById('local_stream');
  }

  _createLocalPlayerContainer() {
    this._removeView(LOCAL_PLAYER_ID);
    const div = document.createElement('div');
    div.setAttribute('id', LOCAL_PLAYER_ID);
    return div;
  }

  async leaveRoom() {
    if (this._channelParameters.localVideoTrack) {
      await this._agoraManager.leave(this._channelParameters);
    }
    this._removeView(`local_video`);
    this._removeView(REMOTE_PLAYER_ID);
  }

  _removeView(id) {
    if (!id) return;
    console.warn('Removing ' + id + 'Div');
    const div = document.getElementById(id);
    if (div) {
      div.remove();
    }
  }

  async toggleVideo() {
    const newVideoStatus = !this._videoOn
    await this._channelParameters.localVideoTrack.setMuted(!newVideoStatus);
    this._videoOn = newVideoStatus;
    return this._videoOn;
  }

 async toggleAudio() {
   const newAudioStatus = !this._audioOn
    await this._channelParameters.localAudioTrack.setMuted(!newAudioStatus);
    this._audioOn = newAudioStatus;
    return this._audioOn;
  }

  on(eventName, listener) {
    if (!this._events[eventName]) {
      this._events[eventName] = [];
    }
    this._events[eventName].push(listener);
  }

  cleanEvents() {
    this._events = {};
  }

  _emit(eventName, input = {}) {
    if (!this._events[eventName]) return;
    this._events[eventName].forEach((listener) => {
      listener(input);
    });
  }

  generateUid(cpf) {
    let cpfTmp = cpf.replace('.', '');
    cpfTmp = cpfTmp.replace('-', '');
    cpfTmp = cpfTmp.replace('.', '');
    cpfTmp = cpfTmp.trim();
    return cpfTmp;
  }

}

export default new VideoCallService();
