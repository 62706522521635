<style src="./PatientVideo.scss" lang="scss" scoped></style>
<template>
  <section class="patient-video">
    <!-- HEADER -->
    <PatientHeader />
    <!-- CONTENT -->
    <section class="content">
      <template v-if="!qsKey">
        <div class="title" v-if="!profissionalEntered">
          Olá, estamos localizando um profissional para realizar seu
          atendimento.
        </div>
        <div class="subtitle" v-if="!profissionalEntered">
          {{ labelCC }} {{ sublabelCC }}
        </div>
      </template>


      <section class="two-columns">
        <!-- VIDEO AREA -->
        <section class="video-area">
          <!-- CONEXAO -->
          <ConnectionLevel v-if="profissionalEntered" :objConnection="objConnection" :room="channelName"/>
          <!--  -->
          <div class="video-screen">
            <div class="expand_stream" id="expand_stream"></div>
          </div>

          <div class="video-local">
            <div class="local_stream" id="local_stream"></div>
          </div>
        </section>
        <!-- *** *** *** -->
        <!-- CHAT -->
        <section class="sec-chat" v-if="profissionalEntered">
          <Chat :objFun="objFun" />
        </section>
      </section>
      <!-- *** *** *** -->
      <div class="video__actions">
        <div
          class="va_btn"
          :class="{ red: videoOn === false }"
          title="Vídeo"
          @click="onClickVideoOnOff"
        >
          <i class="fas fa-video" v-if="videoOn === true" />
          <i class="fas fa-video-slash" v-if="videoOn === false" />
        </div>
        <div
          class="va_btn"
          :class="{ red: audioOn === false }"
          title="Microfone"
          @click="onClickAudioOnOff"
        >
          <i class="fas fa-microphone" v-if="audioOn === true" />
          <i class="fas fa-microphone-slash" v-if="audioOn === false" />
        </div>
      </div>
      <!-- *** *** *** -->
      <!-- <PlButton class="mt" label="PARTIU!" blue save @click="actStartRoom" /> -->
      <PlButton
        class="mt"
        label="Cancelar ligação"
        red
        cross
        @click="onClickCancelCall"
      />
      <div class="title" v-if="!profissionalEntered">Por favor, aguarde!</div>
      <div class="lds-ellipsis" v-if="!profissionalEntered">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
    <!-- *** *** *** -->
    <!-- MODAL ALERT -->
    <section class="alert-all" v-if="showAlert">
      <section class="alert-content">
        <div class="l-header">Informação</div>
        <div class="l-text">{{ alertTxt }}</div>
        <div class="l-footer">Em breve, esta informação fechará</div>
      </section>
    </section>
    <!-- *** *** *** -->
    <!-- MODAL API TIMEOUT -->
    <section class="gateway-timeout__modal" v-if="showGatewayTimeoutMessage">
      <section class="gateway-timeout__modal-content">
        <header class="gateway-timeout__modal-header">Conexão Instável</header>
        <section class="gateway-timeout__modal-body">
          <p>Sua conexão apresenta instabilidade.</p>
          <p>Sugerimos tentar novamente com uma conexão mais estável</p>
        </section>
        <footer class="gateway-timeout__modal-footer">
          <PlButton
            class="mt"
            label="Cancelar"
            red
            @click="onClickCancelCall"
          />
          <PlButton
            class="mt"
            label="Tentar Novamente"
            blue
            @click="startCallingCircleNew"
          />
        </footer>
      </section>
    </section>
    <!-- *** *** *** -->
    <!-- MODAL DELAY -->
    <section class="gateway-timeout__modal" v-if="showServiceDelayAlert">
      <section class="gateway-timeout__modal-content">
        <header class="gateway-timeout__modal-header">
          Seu atendimento está próximo
        </header>
        <section class="gateway-timeout__modal-body">
          <p>
            Estamos com um grande número de consultas, seu atendimento ocorrerá
            em alguns minutos.
          </p>
          <p>Agradecemos sua compreensão.</p>
        </section>
        <footer class="gateway-timeout__modal-footer">
          <PlButton
            class="mt"
            label="Cancelar"
            red
            @click="onClickCancelCall"
          />
          <PlButton
            class="mt"
            label="Continuar"
            blue
            @click="onClickContinueDelayAlert"
          />
        </footer>
      </section>
    </section>
    <!-- MODAL ALERT MEDIA DEVICE PERMISSION -->
<!--    <ModalAlertMediaDevicePermissions @exit="exitModalAlertDevicePermissions" @permissionsGranted="actStartRoom"/>-->
    <!-- *** *** *** -->
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import { ServicoHttp } from '@/axios/servico-http';
import { ServicoHttpCallingCircle } from '@/axios/servico-http-calling-circle';
import { ServicoAll } from '@/axios/servico-all';
import MovementLogs from '@/helpers/movement_logs';

import PatientHeader from "../components/header/PatientHeader";
import Chat from "../components/chat/Chat";

import AgoraToken from '@/services/AgoraToken';
import VideoCallService, { EVENTS } from '@/services/VideoCallService';
import Firebase from '@/helpers/firebase/index';
import PlButton from '@/components/button/Button';
import ConnectionLevel from '@/components/connection-level/ConnectionLevel';

import MediaDeviceMixin from '@/mixin/MediaDeviceMixin';

export default {
  name: 'PortalPatientVideo',
  components: {
    PlButton,
    PatientHeader,
    Chat,
    ConnectionLevel
  },
  mixins: [MediaDeviceMixin],

  data() {
    return {
      qsSystemFrom: null,
      alertTxt: '',
      showAlert: false,
      showGatewayTimeoutMessage: false,
      showServiceDelayAlert: false,

      showModalCallingCircle: false,
      labelCC: null,
      sublabelCC: null,
      statusCancelCall: false,

      patient: null,
      pacId: null,
      pacCpf: null,
      spaId: null,
      vcuId: null,
      qsKey: null,
      qsAgoraToken: null,
      qsAgpId: null,
      objFun: null,
      firestoreIdAgoraToken: null,
      enterTokenRoom: false,

      rtc: {
        client: null,
        joined: false,
        published: false,
        localStream: null,
        remoteStreams: [],
        params: {}
      },
      listRemoteStreams: [],
      listRemoveRemoteStreams: [],
      professionalRemoteStreamId: null,

      videoOn: true,
      audioOn: true,

      agoraUID: null,
      channelName: null,
      agoraToken: null,
      clientRTM: null,
      agoraAppID: '61cf3781778b4a15a3460bd5ab7814bb', // rg homologacao

      isDisabledBtn: true,
      isBtnCamOn: true,
      isCamOff: true,
      isBtnSoundOn: true,
      isBtnMicroOn: true,
      profissionalEntered: false,

      cmdForward: false,
      cmdSendToDoctorQueue: false,
      cmdAskArmOrDoctor: false,
      cmdCancelCall: false,
      cmdShowGatewayTimeoutMessage: false,
      cmdReloadPage: false,
      runningContinueCall: false,
      attendanceData: {},
      qsRefresh: false,

      objConnection: {}
    };
  },
  // *** *** ***
  // *** *** ***
  async beforeDestroy() {
    await this.$store.dispatch('resetPatientToVideoUrl');
    this.actLeaveRoom();
    VideoCallService.cleanEvents();

    // REMOVE FB LISTENERS
    const firebaseUnsubscribers = [
      this.onListenerByPacIdUnsubscribe
    ]
    for (const unsubscribe of firebaseUnsubscribers) {
      if (typeof unsubscribe === 'function') {
        unsubscribe()
      }
    }
  },


  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.servicoHttpCallingCircle = new ServicoHttpCallingCircle(this);

    this.apiCallingCircleServiceRequestEnter = new ServicoAll(
      '/solicitacao-atendimento/enter',
      this.servicoHttpCallingCircle
    );
    this.apiCallingCircleServiceRequestExit = new ServicoAll(
      '/solicitacao-atendimento/exit',
      this.servicoHttpCallingCircle
    );
    this.apiGetLinkAgoraToken = new ServicoAll(
      '/solicitacao-atendimento/get-token',
      this.servicoHttpCallingCircle
    );
    this.apiCheckProfessionalByAgoraToken = new ServicoAll(
      '/solicitacao-atendimento/analise-token',
      this.servicoHttpCallingCircle
    );
    this.apiRemoveAgoraToken = new ServicoAll(
      '/solicitacao-atendimento/remove-token',
      this.servicoHttpCallingCircle
    );
    this.apiGetLinkAgoraTokenInformations = new ServicoAll(
      '/solicitacao-atendimento/get-token-patient',
      this.servicoHttpCallingCircle
    );

    this.sCancelCall = new ServicoAll(
      '/api/pep/circle/patient-cancel-call',
      this.servicoHttp
    );
    this.sUpVideoUrl = new ServicoAll(
      '/api/pep/circle/up-video-url',
      this.servicoHttp
    );
    this.sGetVideoUrl = new ServicoAll(
      '/api/pep/circle/get-video-url',
      this.servicoHttp
    );
    this.sFirstStart = new ServicoAll(
      '/api/pep/circle/first-start',
      this.servicoHttp
    );
    this.sCallDrops = new ServicoAll(
      '/api/pep/fila/add-call-drops',
      this.servicoHttp
    );
    this.sRemovePacQueue = new ServicoAll(
      '/api/pep/circle/remove-pac-queue',
      this.servicoHttp
    );
    this.sSaveHistoric = new ServicoAll(
      '/api/pep/historico-atendimento/save',
      this.servicoHttp
    );

    this.apiCleanPatient = new ServicoAll(
      '/solicitacao-atendimento/limpar-paciente',
      this.servicoHttpCallingCircle
    );

    this.apiCreateScheduleDocumentAgora = new ServicoAll(
      '/agendamento/create',
      this.servicoHttpCallingCircle
    );
    this.apiRemoveScheduleDocumentAgora = new ServicoAll(
      '/agendamento/remove',
      this.servicoHttpCallingCircle
    );
    this.apiGetScheduleDocumentAgora = new ServicoAll(
      '/agendamento/get',
      this.servicoHttpCallingCircle
    );
    this.apiCallingCircleUpdateSchedule = new ServicoAll(
      '/agendamento/update',
      this.servicoHttpCallingCircle
    );

    this.patient = await this.$store.dispatch('getLocalStoragePatient');
    this.pacId = this.patient.pac_id;
    this.pacCpf = this.patient.usu_cpf;
    await Firebase.updatePatientSpecificKey(this.pacId, 'comando', null);

    // * QUERY STRING
    this.qsKey = this.$route.query.key || null;
    this.spaId = this.$route.query.video || null;
    this.qsAgoraToken = this.$route.query.token || null;
    this.qsRefresh = this.$route.query.refresh || null;
    this.qsSystemFrom = this.$route.query.from || null;
    this.qsAgpId = this.$route.query.agendamento || null;

    if (this.qsKey) {
      const patientFromHash = await this.$store.dispatch(
        'getPatientToVideoUrl'
      );
      await this.sRemovePacQueue.post({ pacId: this.patient.pac_id });

      if (patientFromHash) {
        this.patient = patientFromHash;
        this.pacId = this.patient.pac_id;
        this.pacCpf = this.patient.usu_cpf;
      }
    }

    await Firebase.setFirestoreConfig();

    const momBegin = moment(new Date());
    const begin = momBegin.format('YYYY-MM-DD HH:mm:ss');

    await Firebase.updatePatientSpecificKey(
      this.pacId,
      'comecou_ligacao',
      begin
    );

    // ! Firebase LOG
    const objLog = { ...this.patient };
    const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
    MovementLogs.saveLog(1700, timeAction, objLog);

    // *** *** ***
    // * Analise duplicidade de aba com vídeo
    // * Analise de refresh na página (F5)
    this.enterTokenRoom = false;
    const isStatusNull = await Firebase.isPacienteWithStatusNull(
      this.patient.pac_id
    );

    // console.log("@f5 isStatusNull", isStatusNull);
    if (!isStatusNull) {
      // console.log("@f5 tem profissional com este paciente");
      // ! Firebase LOG
      const objLog = {
        ...this.patient,
        qsAgoraToken: this.qsAgoraToken,
        cmdForward: this.cmdForward,
        ...isStatusNull
      };
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
      MovementLogs.saveLog(1712, timeAction, objLog);

      if (this.qsAgoraToken) {
        // console.log("@f5 tem token na url");
        // ! caso tenha um token na url
        let apiProfessional;
        if (this.qsAgpId) {
          apiProfessional = await this.apiGetScheduleDocumentAgora.post({
            agp_id: this.qsAgpId
          });
        } else {
          apiProfessional = await this.apiCheckProfessionalByAgoraToken.post({
            token: this.qsAgoraToken
          });
        }
        const objProfessional = apiProfessional.data.obj;
        if (!objProfessional) {
          // * se nao encontrar nenhum profissional, mesmo tendo um token
          // console.log("!!!objProfessional", objProfessional);
          console.warn('[PatientVideo] sem profisisonal')
          await this.onClickCancelCall();
          return;
        }
        // ! caso encontre um profissional com o mesmo token do agora
        const professionalStatus = objProfessional.status;
        if (professionalStatus !== 'ligação') {
          // * caso o profissional esteja com status diferente de ligação
          // console.log("!!!professionalStatus", professionalStatus);
          console.warn('[PatientVideo] profisisonal em outra ligacao', objProfessional)
          await this.onClickCancelCall();
          return;
        } else if (professionalStatus === 'ligação') {
          // console.log("@f5 profissional esta na sua ligacao");
          // * caso o profissional esteja em ligação, entrar na mesma sala
          this.enterTokenRoom = true;
          await this.getPermissions();
          await this.actStartRoom();
        }
      } else if (this.qsAgpId) {
        // console.warn("@f5 tem qsAgpId na url");
        await this.actStartRoom();
      } else {
        // ! sem token na url
        this.$toast.error('Você já está em outra tela de vídeo', {
          duration: 3000
        });
        this.$router.push({ name: 'pacienteHome' });
      }
      if (!this.enterTokenRoom) {
        return;
      }
    }

    // *** *** ***
    // * LISTENER
    // ! colecao paciente (olha somente para o documento do logado)
    this.onListenerByPacIdUnsubscribe = Firebase.onListenerByPacId(this.pacId, async (snap) => {
      // console.log("[onListenerByPacId] snap", snap);
      if (snap.data.fun_id) {
        // console.log("Mudanca de funcionario", snap.data);
        this.objFun = {
          fun_id: snap.data.fun_id,
          fun_nome: snap.data.fun_nome
        };
      }
      // ! fila sem ser na modificacao
      if (snap.data.filaRequisicaoProntoAtendimento !== 0) {
        this.labelCC = `Você está na fila.`;
        this.sublabelCC = `Seu número é ${snap.data.filaRequisicaoProntoAtendimento}`;
        // ! Firebase LOG
        const objLog = {
          ...this.patient,
          fila: `${this.labelCC}. ${this.sublabelCC}`
        };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        MovementLogs.saveLog(1714, timeAction, objLog);
      } else {
        this.labelCC = ``;
        this.sublabelCC = ``;
        // ! Firebase LOG
        // const objLog = { ...this.patient }
        // const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
        // MovementLogs.saveLog(1715, timeAction, objLog)
      }
      if (snap.type === 'modified') {
        // console.warn("Modifcado. Comando:", snap.data.comando);
        const data = snap.data;
        // ! comandos
        if (data.comando === 'nenhum' || data.comando === null) {
          // console.log("Comando nenhum ou null, nao fara nada");
          // this.cmdSendToDoctorQueue = false;
          // this.cmdForward = false;
          // this.cmdAskArmOrDoctor = false;
          // this.cmdCancelCall = false
        }
        if (data.comando === 'recarregarPagina') {
          // console.log("@recarregarPagina - Limpando os comandos");
          if (this.cmdReloadPage) {
            return;
          }
          window.location.reload();
          this.cmdReloadPage = true;
        }
        if (data.comando === 'finalizandoAtendimento') {
          // console.log("@finalizandoAtendimento - Limpando os comandos");
          this.cmdSendToDoctorQueue = false;
          this.cmdForward = false;
          this.cmdAskArmOrDoctor = false;
          this.cmdCancelCall = false;
          // TODO - chamar o cancelar btn
          if (this.qsAgpId) {
            await this.apiCallingCircleUpdateSchedule.post({
              agp_id: this.$route.query.agendamento,
              status: 'finalizandoAtendimento'
            });
          }

          this.checkProfissionalCallDrop();
        }
        if (data.comando === 'encaminhamentoMedico') {
          if (this.cmdForward) {
            return;
          }
          // console.warn("@encaminhamentoMedico");
          this.profissionalEntered = false;
          this.startTimeoutNotifyProfessionalDelay();
          this.cmdForward = true;
        }
        if (data.comando === 'enviadoParaFilaMedica') {
          if (this.cmdSendToDoctorQueue) {
            return;
          }
          // console.warn("@enviadoParaFilaMedica");
          this.profissionalEntered = false;
          this.startTimeoutNotifyProfessionalDelay();
          this.cmdSendToDoctorQueue = true;
        }
        if (data.comando === 'showGatewayTimeoutMessage') {
          if (this.cmdShowGatewayTimeoutMessage) {
            return;
          }
          this.showGatewayTimeoutMessage = true;
          this.cmdShowGatewayTimeoutMessage = false;
          Firebase.updatePatientSpecificKey(this.pacId, 'comando', null);
        }
        if (
          data.comando === 'perguntaArm' ||
          data.comando === 'perguntaMedico'
        ) {
          if (this.cmdAskArmOrDoctor) {
            return;
          }
          // console.warn("@pergunta", data.comando);
          this.cmdAskArmOrDoctor = false;
          Firebase.updatePatientSpecificKey(this.pacId, 'comando', 'resposta');
        }
        if (data.comando === 'cancelaLigacao') {
          if (this.cmdCancelCall) {
            return;
          }
          console.warn('[PatientVideo] comando para cancelar ligacao')
          this.onClickCancelCall();
          this.cmdCancelCall = true;
        }
        if (data.comando === "restartCallingCircle") {
          console.log('@RG RESTART CC')
          this.startCallingCircleNew()
        }
        // ! fila de requisicao
        if (data.filaRequisicaoProntoAtendimento !== 0) {
          this.labelCC = `Você está na fila.`;
          this.sublabelCC = `Seu número é ${data.filaRequisicaoProntoAtendimento}`;
        } else {
          this.labelCC = ``;
          this.sublabelCC = ``;
        }
      }
    });
    // *** *** ***
    // * CRIAR SALA
    // ! cria a sala quando nao eh f5 e nao tem agpId na url
    if (!this.enterTokenRoom && !this.qsAgpId) {
      await this.getPermissions();
      await this.actStartRoom();
    } else if (this.qsAgpId) {
      // console.warn("Criar sala via qsAgpId");
      await this.actStartRoom();
    }
  },
  // *** *** ***
  // *** *** ***
  methods: {

    // *********
    async onClickContinueDelayAlert() {
      if (this.runningContinueCall) {
        return;
      }
      this.runningContinueCall = true;
      this.showServiceDelayAlert = false;
      await this.getExistingTokenInformationByPatientId(this.pacId);
      this.runningContinueCall = false;
      this.startTimeoutNotifyProfessionalDelay();
    },
    // * VALIDACAO DE TOKEN
    async getExistingTokenInformationByPatientId(patientId) {
      try {
        const respTokenInfo = await this.apiGetLinkAgoraTokenInformations.post({
          patientId: patientId
        });
        if (respTokenInfo.status === 200) {
          const tokenInfo = respTokenInfo.data.obj;
          // console.log("[getExistingTokenInformationByPatientId]", tokenInfo);
          this.hasTokenFirestoreId =
            tokenInfo.hasTokenData && tokenInfo.infoTokenData.firestoreId;
          this.hasTokenSpaId =
            tokenInfo.hasTokenData && tokenInfo.infoTokenData.spa_id;
          // const hasToken = tokenInfo.infoTokenData.token
          if (tokenInfo.hasEmergencyServiceRequestQueue) {
            // ! está na fila de requisição
            const numServiceRequest =
              tokenInfo.infoEmergencyServiceRequestQueue
                .numberEmergencyServiceRequestQueue;
            const msg = `Você está na fila, posição ${numServiceRequest}.`;
            // console.warn("[getExistingTokenInformationByPatientId]", msg);
            this.$toast.success(msg, { duration: 5000 });
          } else if (tokenInfo.hasCallingCircleList) {
            // ! está no calling circle
            const msg = 'Você está prestes a ser atendido.';
            // console.warn("[getExistingTokenInformationByPatientId]", msg);
            this.$toast.success(msg, { duration: 5000 });
          } else if (tokenInfo.hasDoctorsQueue) {
            // ! está na fila médica
            const msg = 'Você está prestes a ser atendido pelo médico.';
            // console.warn("[getExistingTokenInformationByPatientId]", msg);
            this.$toast.success(msg, { duration: 5000 });
          } else if (tokenInfo.hasTokenData) {
            // ! se tem somente o token
            // console.warn(
            //   "[getExistingTokenInformationByPatientId]",
            //   "Remover token do firestore",
            //   tokenFirestoreId
            // );
            await this.apiRemoveAgoraToken.post({
              firestoreId: tokenFirestoreId
            });
            await this.startCallingCircleNew();
          } else {
            // ! nao tem nada
            console.info(
              '[getExistingTokenInformationByPatientId]',
              'Seguir para uma solicitacao comum'
            );
            await this.startCallingCircleNew();
          }
        }
      } catch (err) {
        console.error('[getExistingTokenInformationByPatientId]', 'Erro', err);
      }
    },
    // *********
    // * ABRIR SALA
    async actStartRoom() {
      await this.getPermissions()
      let objToken = {};
      if (this.qsKey || this.qsAgpId) {
        await this.apiRemoveScheduleDocumentAgora.post({
          agp_id: this.qsAgpId
        }); // pleni/pleni#1511
        // ! Valida se já tem um AgpId no Firestore (colecao agendamento-agora)
        let docAgp = await this.apiGetScheduleDocumentAgora.post({
          agp_id: this.qsAgpId
        });
        docAgp = docAgp.data.obj;
        if (docAgp === null) {
          // ! se não tem o docAgp, cria um novo
          // console.log("@f5 nao encontrou documento com agpId = ", this.qsAgpId);
          // console.log("@actStartRoom - agendamento");
          // console.log("@actStartRoom - qsAgpId", this.qsAgpId);
          const date = Date.now();
          const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          let randomLetters = '';
          for (let i = 0; i < 3; i++) {
            randomLetters += alphabet.charAt(
              Math.floor(Math.random() * alphabet.length)
            );
          }

          this.agoraUID = VideoCallService.generateUid(this.pacCpf);
          console.warn('AGORA-UID',this.agoraUID);
          this.channelName = `AGP_${randomLetters}_${this.agoraUID}_${date}`;

          // * pegar token
          objToken = {
            canal: this.channelName,
            uid: this.agoraUID
          };
          let tmpToken = await AgoraToken.getToken(objToken);
          this.agoraToken = tmpToken;
          objToken.token = tmpToken;

          const objScheduleFirestore = {
            canal: this.channelName,
            uid: this.agoraUID,
            token: tmpToken,
            pac_id: this.patient.pac_id,
            pac_nome: this.patient.pac_nome,
            usu_cpf: this.patient.usu_cpf,
            agp_id: this.qsAgpId
          };
          await this.apiCreateScheduleDocumentAgora.post(objScheduleFirestore);
        } else {
          // ! se tem o docAgp, entra na sala do criado
          // console.log("@f5 encontrou documento agpId", docAgp);
          console.warn('opa to no else')
          this.agoraUID = docAgp.uid;
          this.channelName = docAgp.canal;
          this.agoraToken = docAgp.token;
          objToken = {
            canal: this.channelName,
            uid: this.agoraUID,
            token: this.agoraToken
          };
        }

        // console.warn("@actStartRoom abrir sala por meio de link", objToken);
        const objVideo = await this.sGetVideoUrl.post({ key: this.qsKey });
        if (objVideo.data.status) {
          const objRoom = objVideo.data.data;
          this.vcuId = objRoom.vcu_id;
          const obj = {
            vcu_id: this.vcuId,
            vcu_nome_sala: this.channelName,
            vcu_agora_token: this.agoraToken,
            vcu_agora_uid: this.agoraUID
          };
          // console.log("@actStartRoom obj", obj);
          // console.warn("@ROUTE", this.$route);
          this.$router.replace({
            name: this.$route.name,
            query: { ...this.$route.query, token: this.agoraToken }
          });
          this.qsAgoraToken = this.agoraToken;
          await this.sUpVideoUrl.post(obj);
        } else {
          console.error('Nao foi possivel pegar dados do video');
        }
      }
      // ! paciente ligando
      else {
        if (!this.qsAgoraToken) {
          this.$toast.error(
            'Não foi possível iniciar a sala. Faltando o token da chamada.',
            {
              duration: 3000
            }
          );
          return;
        }
        // * pegar token
        const objApiToken = await this.apiGetLinkAgoraToken.post({
          token: this.qsAgoraToken,
          spaId: this.spaId
        });
        const objTokenData = objApiToken.data.obj;
        if (!objTokenData) {
          this.$toast.error(
            'Não foi possível iniciar a sala. Não encontrou o documento com o token.',
            {
              duration: 3000
            }
          );
          return;
        }
        this.firestoreIdAgoraToken = objTokenData.firestoreId;
        this.agoraUID = objTokenData.uid;
        this.channelName = objTokenData.canal;
        objToken = {
          canal: this.channelName,
          uid: this.agoraUID,
          token: objTokenData.token
        };
        this.agoraToken = objTokenData.token;
      }

      // criar sala
      console.log('this.agoraUID',this.agoraUID)
      this.listerAgoraRtc();
      const uid = await VideoCallService.joinChannel({
        uid: this.agoraUID,
        channel: this.channelName,
        token: this.agoraToken,
        cpf: this.pacCpf
      });
      this.isCamOff = false;
      this.isDisabledBtn = false;
      this.startTimeoutNotifyProfessionalDelay();
      // ! paciente ligando
      if (!this.qsKey) {
        if (!this.enterTokenRoom) {
          this.startCallingCircleNew();
        } else {
          // console.log("@f5 entrar na sala do f5");
          if (this.objFun?.fun_id) {
            await Firebase.sendCommandProfessionalByFunId(
              this.objFun.fun_id,
              'resetarSala'
            );
          }
          if (this.qsRefresh) {
            const url = new URL(window.location.href);
            url.searchParams.delete('refresh');
            const newUrl = url.toString();
            window.location.href = newUrl;
            this.qsRefresh = false;
          }
        }
      }
      this.$forceUpdate();
    },

    async listerAgoraRtc() {
      VideoCallService.on(EVENTS.USER_JOINED, (input) => {
        this.professionalRemoteStreamId = input.user.uid.toString();
        this.funcionarioEnteredVideo();
        this.profissionalEntered = true;
        const objLog = { ...this.patient };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        MovementLogs.saveLog(1708, timeAction, objLog);
      });
      VideoCallService.on(EVENTS.USER_LEFT, (input) => {
        this.checkProfissionalCallDrop();
      });
      VideoCallService.on(EVENTS.NETWORK_QUALITY, (input) => {
        this.objConnection = {
          ...input,
          ...this.patient
        }
      });
    },

    actLeaveRoom() {
      VideoCallService.leaveRoom();
    },

    // *********
    // * ACOES DO RTC LOCAL
    async onClickVideoOnOff() {
      try {
        const isOn = await VideoCallService.toggleVideo();
        this.videoOn = isOn;
        const objLog = { ...this.patient };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        const code = isOn ? 1702 : 1703;
        MovementLogs.saveLog(code, timeAction, objLog);
      } catch (e) {
        console.error('Erro onClickVideoOnOff', e);
        this.$toast.error('Falha o ativar/desativar vídeo.');
      }
    },

    async onClickAudioOnOff() {
      try {
        const isOn = await VideoCallService.toggleAudio();
        this.audioOn = isOn;
        const objLog = { ...this.patient };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        const code = isOn ? 1704 : 1705;
        MovementLogs.saveLog(code, timeAction, objLog);
      } catch (e) {
        console.error('Erro onClickAudioOnOff', e);
        this.$toast.error('Falha o ativar/desativar audio.');
      }
    },
    // *** *** ***
    // * ACAO EXTRA
    async checkProfissionalCallDrop() {
      // console.warn("@f5 Profissional saiu da chamada", this.patient);
      // ! Firebase LOG
      const objLog = { ...this.patient };
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
      MovementLogs.saveLog(1709, timeAction, objLog);

      // console.log("TESTE", {
      //   qsAgoraToken: this.qsAgoraToken,
      //   cmdForward: this.cmdForward,
      // });

      // ! caso tenha um token na url e nao for encaminhamento
      if (this.qsAgoraToken && !this.cmdForward) {
        // console.log("@f5 do profissional");
        // ! Firebase LOG
        const objLog = {
          ...this.patient,
          qsAgoraToken: this.qsAgoraToken,
          cmdForward: this.cmdForward
        };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        MovementLogs.saveLog(1713, timeAction, objLog);

        let apiProfessional;
        if (this.qsAgpId) {
          apiProfessional = await this.apiGetScheduleDocumentAgora.post({
            agp_id: this.qsAgpId
          });
        } else {
          apiProfessional = await this.apiCheckProfessionalByAgoraToken.post({
            token: this.qsAgoraToken
          });
        }

        // console.log("apiProfessional", JSON.stringify(apiProfessional.data));
        const objProfessional = apiProfessional.data.obj;
        if (objProfessional) {
          // * se encontrar profissional com o mesmo token
          if (objProfessional.status === 'ligação') {
            this.profissionalEntered = false;
            this.startTimeoutNotifyProfessionalDelay();
            this.showHideAlert(
              true,
              'Profissional saiu da sala. Aguarde um momento.'
            );
            // console.log(
            //   "@f5 profissional esta na sua ligacao, pode ser que ele volte"
            // );
            return;
          }
        }
      }
      // como são chaves simples, nao precisa jogar para a api
      await Firebase.updatePatientSpecificKey(this.pacId, 'comando', null);
      await Firebase.updatePatientSpecificKey(this.pacId, 'fun_id', null);
      await Firebase.updatePatientSpecificKey(this.pacId, 'fun_nome', null);

      if (this.qsAgpId) {
        await this.apiRemoveScheduleDocumentAgora.post({
          agp_id: this.qsAgpId
        });
      }
      // ! caso seja encaminhamento
      // console.log("this.cmdForward", this.cmdForward);
      if (this.cmdForward) {
        this.showHideAlert(true, 'Estamos transferindo para o Médico', false);
        // ! Firebase LOG
        const objLog = { ...this.patient, cmdForward: this.cmdForward };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        MovementLogs.saveLog(1717, timeAction, objLog);
        // como são chaves simples, nao precisa jogar para a api
        Firebase.updatePatientSpecificKey(this.pacId, 'comando', null);
        this.cmdForward = false;
        return;
      }
      // ! caso seja enviado para a fila médica
      // console.log("this.cmdSendToDoctorQueue", this.cmdSendToDoctorQueue);
      if (this.cmdSendToDoctorQueue) {
        this.showHideAlert(
          true,
          'Você será direcionado para a Fila do Atendimento Médico',
          false
        );
        // como são chaves simples, nao precisa jogar para a api
        Firebase.updatePatientSpecificKey(this.pacId, 'comando', null);
        this.cmdSendToDoctorQueue = false;
        return;
      }
      // ! se for um queda, comum, do profissional
      // console.warn("Sair da sala tbm");
      const pacId = this.patient.pac_id;
      // como são chaves simples, nao precisa jogar para a api
      Firebase.updatePatientSpecificKey(pacId, 'comando', null);
      if (this.objFun.fun_id) {
        await this.sCallDrops.post({
          pacId: pacId,
          funId: this.objFun.fun_id,
          cliId: this.patient.usu_id_cliente
        });
      }
      this.showHideAlert(true, 'Seu atendimento foi finalizado', true);
      this.actLeaveRoom();
    },

    async onClickCancelCall() {
      console.warn('[PatientVideo] onClickCancellCall()')
      this.showGatewayTimeoutMessage = false;
      try {
        if (this.statusCancelCall) {
          // console.warn(
          //   "[[PatientVideo]] Processo de cancelamento em andamento"
          // );
          return;
        }
        this.statusCancelCall = true;
        this.showHideAlertNoTime(
          true,
          'Sua chamada está sendo cancelada. Por favor, aguarde um momento.'
        );
        if (this.qsAgpId) {
          await this.apiRemoveScheduleDocumentAgora.post({
            agp_id: this.qsAgpId
          });
        }

        await this.apiCallingCircleServiceRequestExit.post({
          patientId: this.pacId
        });
        await this.apiRemoveAgoraToken.post({
          firestoreId: this.firestoreIdAgoraToken
        });
        await this.apiCleanPatient.post({
          patientId: this.pacId,
          cleanByPatient: true
        });

        // Cria registro de Pronto Atendimento Cancelado no historico
        const paramsHist = {
          hat_id_funcionarios:
            this.objFun && this.objFun.fun_id ? this.objFun.fun_id : null,
          hat_id_pacientes: this.pacId,
          hat_id_clientes: null,
          hat_id_atendimentos: null,
          hat_id_tipo_historico: this.qsAgpId ? 2 : 5, // Consulta cancelada ou Pronto Atendimento Cancelado
          hat_id_mevo_documentos: null,
          hat_id_agendamentos_pacientes: this.qsAgpId ? this.qsAgpId : null
        };
        await this.sSaveHistoric.post(paramsHist);

        await this.actLeaveRoom();
        // Cria registro de Pronto Atendimento Cancelado no historico
        const objCancel = {
          pacId: this.pacId,
          isMeet: this.qsKey ? true : false,
          vcuId: this.qsKey ? this.vcuId : null
        };
        const ret = await this.sCancelCall.post(objCancel);

        // ! Firebase LOG
        const objLog = { ...this.patient, ...objCancel };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        MovementLogs.saveLog(1701, timeAction, objLog);

        if (ret.data.status === true) {
          this.showHideAlertNoTime(false, null);
          await this.gotoNps();
        } else {
          this.showHideAlertNoTime(false, null);
          this.showHideAlert(
            true,
            'O cancelamento da sua chamada falhou. Por favor, tente novamente ou entre em contato com o suporte.'
          );
        }
        await this.gotoNps();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 504) {
            this.showHideAlertNoTime(false, null);
            this.showGatewayTimeoutMessage = true;
            return;
          }
        }
        this.showHideAlert(
          true,
          'O cancelamento da sua chamada falhou. Por favor, tente novamente ou entre em contato com o suporte.'
        );
        // console.log("Error ao cancelar", error);
      } finally {
        this.statusCancelCall = false;
      }
    },
    // *** *** ***
    // * FIRESTORE
    async funcionarioEnteredVideo() {
      Firebase.updatePatientSpecificKey(
        this.pacId,
        'status',
        Firebase.PACIENTE_STATUS.LIGACAO
      );
      const spaId = this.spaId || null;
      const agoraUID = this.agoraUID || null;
      const agoraRoomName = this.channelName || null;
      const agoraToken = this.agoraToken || null;
      const pacId = this.pacId || null;
      const pacNome = this.patient.pac_nome;
      Firebase.funcionarioEnteredVideo(
        pacId,
        pacNome,
        spaId,
        agoraUID,
        agoraRoomName,
        agoraToken
      );
    },

    async startCallingCircleNew() {
      this.showGatewayTimeoutMessage = false;
      const momNow = moment();
      const momBirth = moment(this.patient.usu_nascimento, 'YYYY-MM-DD');
      const age = momNow.diff(momBirth, 'years');
      let sex = 'Não informado';
      if (this.patient.usu_genero) {
        sex = this.patient.usu_genero === 'm' ? 'Masculino' : 'Feminino';
      }
      const payload = {
        isWeb: true,
        systemVersion: this.appVersion,
        pac_id: this.patient.pac_id,
        pac_nome: this.patient.pac_nome,
        pac_genero: sex,
        pac_idade: age,
        pac_imagem: this.patient.usu_imagem_url,
        usu_cpf: this.patient.usu_cpf,
        spa_id: this.spaId,
        agoraUID: this.agoraUID, // nao deve estar usando mais
        agoraRoomName: this.channelName, // nao deve estar usando mais
        agoraToken: this.agoraToken, // nao deve estar usando mais
        agoraTokenFirestoreId: this.firestoreIdAgoraToken
      };

      try {
        // console.log("--- --- ---");
        const responseApiCallingCircle =
          await this.apiCallingCircleServiceRequestEnter.post(payload);
        // console.log(
        //   "enviado para entrar no calling circle",
        //   responseApiCallingCircle
        // );
        // console.log("--- --- ---");
      } catch (error) {
        this.showGatewayTimeoutMessage = true;
      }
    },

    async startCallingCircleOld() {
      const spaId = this.spaId || null; // solicitacao de atendimento (id)
      const agoraUID = this.agoraUID || null;
      const agoraRoomName = this.channelName || null;
      const agoraToken = this.agoraToken || null;
      const momNow = moment();
      const momBirth = moment(this.patient.usu_nascimento, 'YYYY-MM-DD');
      const age = momNow.diff(momBirth, 'years');
      const objPac = {
        pacId: this.patient.pac_id,
        pacNome: this.patient.pac_nome,
        pacGenero: this.patient.usu_genero === 'm' ? 'Masculino' : 'Feminino',
        pacIdade: age,
        pacImagem: this.patient.usu_imagem_url,
        usuCpf: this.patient.usu_cpf || 'Não Informado'
      };
      // console.warn("[startCallingCircle] objPac", objPac);
      Firebase.startCallingCircle(
        objPac,
        spaId,
        agoraUID,
        agoraRoomName,
        agoraToken
      );
    },

    async startMedicCallingCircle(specific) {
      const spaId = this.spaId || null;
      const agoraUID = this.agoraUID || null;
      const agoraRoomName = this.channelName || null;
      const agoraToken = this.agoraToken || null;
      const momNow = moment();
      const momBirth = moment(this.patient.usu_nascimento, 'YYYY-MM-DD');
      const age = momNow.diff(momBirth, 'years');
      const objPac = {
        pacId: this.patient.pac_id,
        pacNome: this.patient.pac_nome,
        pacGenero: this.patient.usu_genero === 'm' ? 'Masculino' : 'Feminino',
        pacIdade: age || 0,
        pacImagem: this.patient.usu_imagem_url || null,
        pacCpf: this.patient.usu_cpf || null,
        usuCpf: this.patient.usu_cpf || 'Não Informado',
        specific: specific
      };
      // console.warn("[startMedicCallingCircle] objPac", objPac);
      await Firebase.stopCallingCircle();
      await Firebase.removePacIdFromDocuments(this.pacId);
      Firebase.startMedicCallingCircle(
        objPac,
        spaId,
        agoraUID,
        agoraRoomName,
        agoraToken
      );
    },
    // *** *** ***
    // * ALERTA
    async showHideAlert(status, txt, gotoNps = false) {
      this.alertTxt = txt;
      this.showAlert = status;
      setTimeout(async () => {
        this.showAlert = false;
        this.alertTxt = null;
        if (gotoNps) {
          // this.$router.push({ name: "pacienteHome" });
          await this.gotoNps();
        }
      }, 8 * 1000);
    },

    startTimeoutNotifyProfessionalDelay() {
      if (this.profissionalEntered || this.showServiceDelayAlert) {
        return;
      }
      this.showServiceDelayAlert = false;
      // console.warn("@timeout", "iniciando o timeout de tempo de espera");
      const seg = 60;
      const min = 5;
      setInterval(() => {
        if (this.profissionalEntered || this.showServiceDelayAlert) {
          return;
        }
        this.showServiceDelayAlert = true;
      }, min * seg * 1000);
    },

    async showHideAlertNoTime(status, txt) {
      this.alertTxt = txt;
      this.showAlert = status;
    },

    async getPermissions() {
      try {
        // ! Firebase LOG
        const objLog = { ...this.patient };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        MovementLogs.saveLog(1706, timeAction, objLog);

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        });
        // console.log("a", stream);
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        // ! Firebase LOG
        const objLog = { ...this.patient };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        MovementLogs.saveLog(1707, timeAction, objLog);

        // console.log("b", error);
        // console.warn("Erro ao solicitar permissão:", error);
        this.$toast.error('A Webcam/Microfone não estão liberados para o uso', {
          duration: 3000
        });
      }
    },

    async gotoNps() {
      // if (!this.profissionalEntered) {
      //   return this.$router.push({ name: "pacienteHome" });
      // }

      // * pega informacoes do ULTIMO atendimento
      const response = await this.servicoHttp.get({
        url: 'api/pep/atendimento/obter-ultimo-atendimento',
        params: { pacId: this.pacId }
      });
      if (response.data.status) {
        this.attendanceData = { ...response.data.data };
      }

      if (this.attendanceData?.atd_id) {
        const params = {
          name: 'pacienteNps',
          query: {
            video: this.attendanceData?.atd_id,
            from: this.qsSystemFrom
          }
        };
        return this.$router.push(params);
      } else {
        return this.$router.push({ name: 'pacienteHome' });
      }
    }
  }
};
</script>
