import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";

// dicionario em: SELECT * FROM lista_logs;
const saveLog = async (code, timeAction, obj) => {
  const servicoHttp = new ServicoHttp(this)
  const sLogSave = new ServicoAll('/api/movement-log/save-log', servicoHttp)
  const objPayload = {
    code: code,
    timeAction: timeAction,
    payload: { ...obj }
  }
  await sLogSave.post(objPayload)
}

const saveLevelRttArray = async (payload) => {
  const servicoHttp = new ServicoHttp(this)
  const sLevelRttArray = new ServicoAll('/api/movement-log/save-level-rtt', servicoHttp)
  await sLevelRttArray.post(payload)
}

const getLevelRttArray = async (payload) => {
  const servicoHttp = new ServicoHttp(this)
  const gLevelRttArray = new ServicoAll('/api/movement-log/get-level-rtt', servicoHttp)
  const ret = await gLevelRttArray.post(payload)
  return ret
}

// *** *** ***
export default {
  saveLog,
  saveLevelRttArray,
  getLevelRttArray
}
