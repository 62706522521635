import AgoraRTC from 'agora-rtc-sdk-ng';
import VirtualBackgroundExtension from 'agora-extension-virtual-background';
import { EVENTS } from './VideoCallService';

const AGORA_API_KEY = '61cf3781778b4a15a3460bd5ab7814bb';

export class AgoraManager {
  _agoraEngine = null;
  _virtualBackgroundProcessor = null;
  constructor(eventHandlerCallback) {
    // eslint-disable-next-line new-cap
    this._agoraEngine = new AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8'
    });
    this._verifyVirtualExtension();
    this._startListeners();
    this._eventHandlerCallback = eventHandlerCallback;
  }

  _verifyVirtualExtension() {
    const virtualBackgroundExtension = new VirtualBackgroundExtension();
    if (!virtualBackgroundExtension.checkCompatibility()) {
      console.error('Sem suporte para fundo virtual!');
    }
    AgoraRTC.registerExtensions([virtualBackgroundExtension]);
    this._virtualBackgroundProcessor = null;
  }

  _startListeners() {
    this._agoraEngine.on('user-left', async (user, mediaType) => {
      const remoteUsers = this._agoraEngine.remoteUsers;
      this._eventHandlerCallback(EVENTS.USER_LEFT, {
        user,
        mediaType,
        remoteUsers
      });
    });
    this._agoraEngine.on('user-info-updated', async (user, mediaType) => {
      this._eventHandlerCallback(EVENTS.INFO_UPDATE, { user, mediaType });
    });
    this._agoraEngine.on('user-published', async (user, mediaType) => {
      await this._agoraEngine.subscribe(user, mediaType);
      this._eventHandlerCallback(EVENTS.USER_PUBLISHED, { user, mediaType });
    });
    this._agoraEngine.on('user-joined', async (user) => {
      // await this._agoraEngine.subscribe(user,'audio');
      // await this._agoraEngine.subscribe(user,'video');
      this._eventHandlerCallback(EVENTS.USER_JOINED, { user, mediaType:null });
    });
    this._agoraEngine.on('user-unpublished', (user) => {
      this._eventHandlerCallback(EVENTS.USER_UNPUBLISHED, { user });
    });
    this._agoraEngine.on('network-quality', (quality) => {
      const uplinkVideoStats = this._agoraEngine.getLocalVideoStats();
      this._eventHandlerCallback(EVENTS.NETWORK_QUALITY, {
        ...quality,
        ...uplinkVideoStats
      });
    });
  }

  async join(
    appendLocalPlayerContainer,
    localPlayerContainer,
    channelParameters,
    joinParameters
  ) {
    const uid = await this._agoraEngine.join(
      AGORA_API_KEY,
      joinParameters.channelName,
      joinParameters.token,
      joinParameters.uid
    );

    // Cria faixas de áudio e vídeo locais
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

    // Define o tamanho do vídeo local
    localPlayerContainer.style.width = '100%';
    localPlayerContainer.style.height = '100%';
    appendLocalPlayerContainer.append(localPlayerContainer);

    // Publica faixas de áudio e vídeo
    await this._agoraEngine.publish([
      channelParameters.localAudioTrack,
      channelParameters.localVideoTrack
    ]);

    // Toca o vídeo local
    channelParameters.localVideoTrack.play(localPlayerContainer);
    return uid;
  }

  async leave(channelParameters) {
    if (
      !channelParameters.localAudioTrack ||
      !channelParameters.localVideoTrack
    ) {
      return;
    }
    channelParameters.localAudioTrack.close();
    channelParameters.localVideoTrack.close();
    await this._agoraEngine.leave();
  }
}
