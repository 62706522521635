import axios from 'axios';

const getToken = async obj => {
  try {
    const url = `${process.env.VUE_APP_BACKEND_AGORA_ENDPOINT}/telemedicina/gerar-token`
    const tokenClient = process.env.VUE_APP_PEP_CLI_TOKEN
    const tokenUser = process.env.VUE_APP_PEP_USU_TOKEN

    const body = {
      cli_token: tokenClient,
      usu_token: tokenUser,
      ...obj
    }
    const response = await axios.post(url, body)
    let token = response.data.token
    return token
  } catch (error) {
    return null
  }
}
export default {
  getToken
}
